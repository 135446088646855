import { useEditorStore, EditorStoreContext } from "@adflow/editor";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { FC, useCallback, useState, useMemo, useContext } from "react";
import StepOne from "./stepOne";
import StepTwo, { StepTwoSubmit } from "./stepTwo";
import { AdTemplate } from "@adflow/types";

export interface StepProps {
  onClose: () => void;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreatomateComponentImportModal: FC<Props> = ({ isOpen, onClose }) => {
  const ctx = useContext(EditorStoreContext);
  const [importedAdTemplate, setImportedAdTemplate] =
    useState<AdTemplate | null>(null);
  const [thumbnailURL, setThumbnailURL] = useState<string>("");
  const s = useEditorStore(state => ({
    addElements: state.addElements,
    setImportSourceJSON: state.setImportSourceJSON,
    setImportSourceType: state.setImportSourceType,
    upsertDataSources: state.upsertDataSources,
    setThumbnailURL: state.setThumbnailURL,
    resetElementData: state.resetElementData,
    setVideoProjectId: state.setVideoProjectId
  }));

  const handleDataFetched = (adTemplate: AdTemplate, thumbnailURL: string) => {
    setImportedAdTemplate(adTemplate);
    setThumbnailURL(thumbnailURL);
  };

  const handleClose = useCallback(async () => {
    onClose();
  }, [onClose]);

  const handleSubmit: StepTwoSubmit = useCallback(
    async (elementsToImport, dataSourceTypes) => {
      try {
        s.resetElementData();
        s.addElements(elementsToImport);

        s.setImportSourceJSON(importedAdTemplate?.importSourceJSON ?? "");
        s.setImportSourceType(importedAdTemplate?.importSourceType);
        s.setThumbnailURL(thumbnailURL);
        s.setVideoProjectId(importedAdTemplate?.videoProjectId ?? null);
        if (dataSourceTypes?.length) {
          s.upsertDataSources(dataSourceTypes, ctx.dataSources);
        }
      } catch (err) {
        console.log(err);
      }

      handleClose();
    },
    [
      ctx.dataSources,
      handleClose,
      importedAdTemplate?.importSourceJSON,
      importedAdTemplate?.importSourceType,
      importedAdTemplate?.videoProjectId,
      s,
      thumbnailURL
    ]
  );

  const CurrentStep = useMemo(() => {
    if (importedAdTemplate?.importSourceJSON) {
      return (
        <StepTwo
          onClose={handleClose}
          onSubmit={handleSubmit}
          importSourceJSON={importedAdTemplate.importSourceJSON}
          elements={importedAdTemplate.adElements}
        ></StepTwo>
      );
    }
    return (
      <StepOne
        onClose={handleClose}
        onDataFetched={handleDataFetched}
      ></StepOne>
    );
  }, [importedAdTemplate, handleClose, handleSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid='importFromCreatomateDialogTitle'>
          Import from Creatomate
        </ModalHeader>
        <ModalCloseButton />
        {CurrentStep}
      </ModalContent>
    </Modal>
  );
};

CreatomateComponentImportModal.displayName = "CreatomateComponentImportModal";

export default CreatomateComponentImportModal;
