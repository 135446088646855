import { useMemo } from "react";

import * as Adflow from "adflow";

import {
  APIClientContext as FigmaAPIClientContext,
  name as FigmaName,
  EditorSidebarButton as FigmaSidebarButton
} from "@adflow/figma";

import {
  name as MarketingCloudName,
  EditorSidebarButton as MarketingCloudSidebarButton,
  EditorSidebarView as MarketingCloudSidebarView,
  APIClientContext as SelectAdvertiserAPIClientContext
} from "@adflow/marketing-cloud";
import {
  APIClientContext as ShadowBrandAPIClientContext,
  name as ShadowBrandName,
  EditorSidebarButton as ShadowBrandSidebarButton,
  EditorSidebarView as ShadowBrandSidebarView
} from "@adflow/shadow-brand";

import {
  APIClientContext as CreatomateAPIContextProvider,
  name as CreatomateName,
  EditorSidebarButton as CreatomateSidebarButton
} from "@adflow/creatomate";

import { Datasources, EditorState } from "@adflow/types";
import { TRPCClient } from "../utils/apiClient";

function Create() {
  const modules = useMemo(
    () => [
      {
        name: FigmaName,
        EditorSidebarButton: FigmaSidebarButton
      },
      {
        name: CreatomateName,
        EditorSidebarButton: CreatomateSidebarButton
      },
      {
        name: ShadowBrandName,
        EditorSidebarButton: ShadowBrandSidebarButton,
        EditorSidebarView: ShadowBrandSidebarView
      },
      {
        name: MarketingCloudName,
        EditorSidebarButton: MarketingCloudSidebarButton,
        EditorSidebarView: MarketingCloudSidebarView
      }
    ],
    []
  );

  const dataSources = Datasources.createDataSourceDefinitions(null);

  const renderPreview = (args: Adflow.RenderPreviewArgs) => {
    return args.importSourceType === "creatomate" && args.importSourceJSON ? (
      <Adflow.CreatomatePreview
        source={args.importSourceJSON}
        elements={args.elements}
        brand={args.brand}
        displayOptions={args.displayOptions}
      />
    ) : (
      <Adflow.Preview
        elements={args.elements}
        brand={args.brand}
        fonts={args.fonts}
        displayOptions={args.displayOptions}
        selectedElementId={args.selectedElementId}
        isIndexPreview={false}
      />
    );
  };

  const onSave = async (editorState: EditorState) => {
    await TRPCClient.client.adTemplate.create.mutate({
      name: editorState.name,
      brandId: editorState.brand?._id || null,
      dataSources: editorState.dataSources,
      adElements: editorState.elements,
      displayOptions: editorState.displayOptions,
      thumbnail: editorState.thumbnailURL,
      businessEntityId: editorState.businessEntityId,
      importSourceJSON: editorState.importSourceJSON,
      importSourceType: editorState.importSourceType,
      videoProjectId: editorState.videoProjectId
    });
  };
  return (
    <div
      className='container'
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Adflow.EditorProvider dataSources={dataSources}>
        <FigmaAPIClientContext.Provider {...TRPCClient.figmaClient}>
          <CreatomateAPIContextProvider.Provider
            {...TRPCClient.creatomateClient}
          >
            <ShadowBrandAPIClientContext.Provider {...TRPCClient.consoleClient}>
              <SelectAdvertiserAPIClientContext.Provider
                {...TRPCClient.marketingCloudClient}
              >
                <Adflow.Editor
                  onSave={onSave}
                  modules={modules}
                  renderPreview={renderPreview}
                ></Adflow.Editor>
              </SelectAdvertiserAPIClientContext.Provider>
            </ShadowBrandAPIClientContext.Provider>
          </CreatomateAPIContextProvider.Provider>
        </FigmaAPIClientContext.Provider>
      </Adflow.EditorProvider>
    </div>
  );
}

export default Create;
