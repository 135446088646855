import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import env from "./env";
import { useContext } from "react";
import UserContext from "../UserProvider/contexts/UserContext";

if (!env.routerBasePath) {
  console.error("Please set the env routerBasePath");
}
export function Index() {
  const { user } = useContext(UserContext);
  return (
    <Parcel
      config={() => import("catalogue-management/App")}
      wrapWith='div'
      wrapStyle={{ height: "100%" }}
      routerBasePath={env.routerBasePath}
      handleError={err => console.log(err)}
      mountParcel={mountRootParcel}
      parcelDidMount={() => console.log("catalogue-management parcel mounted")}
      user={user}
    />
  );
}
