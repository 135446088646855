import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import env from "./env";
import { useContext } from "react";
import UserContext from "../UserProvider/contexts/UserContext";

if (!env.routerBasePath) {
  console.error("Please set the env routerBasePath");
}
export function Index() {
  const { user } = useContext(UserContext);
  return (
    <div>
      <Parcel
        config={() => import("creative-hub-vue/App")}
        wrapWith='div'
        handleError={err => console.log(err)}
        mountParcel={mountRootParcel}
        parcelDidMount={() => console.log("ad-tags parcel mounted")}
        user={user}
      />
    </div>
  );
}
