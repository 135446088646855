import { FC, forwardRef, useMemo } from "react";

import { FaTrashAlt } from "react-icons/fa";
import { shallow } from "zustand/shallow";

import {
  isImageElement,
  isTeamAssetElement,
  isTextElement,
  isVectorElement
} from "@adflow/types";
import { Box, Button, Flex, Icon } from "@chakra-ui/react";

import { useStore } from "../../hooks/useStore";
import ImageElementControls from "./ImageElementControls";
import TextElementControls from "./TextElementControls";
import TeamAssetElementControls from "./TeamAssetControls";
import VectorElementControls from "./VectorElementControls";

const Rightsidebar: FC = () => {
  const s = useStore(
    state => ({
      elements: state.elements,
      selectedElementId: state.selectedElementId,
      importSourceType: state.importSourceType
    }),
    shallow
  );

  const selectedElement = useMemo(() => {
    return s.elements.find(el => el.id === s.selectedElementId);
  }, [s.elements, s.selectedElementId]);

  if (selectedElement == null) {
    return null;
  }

  return (
    <Flex
      display='flex'
      py={4}
      borderLeftWidth={1}
      direction='column'
      minW='350px'
      maxW='450px'
      width='25vw'
      // TODO: re-enable
      // position='absolute'
      // right='0'
      height='100%'
      bg='white'
    >
      <Flex
        alignItems='flex-start'
        justifyContent='space-between'
        px={6}
        direction='column'
        gap={2}
        height='100%'
        overflowY='auto'
      >
        <Flex width='100%' direction='column'>
          {isTextElement(selectedElement) && (
            <TextElementControls
              // The "key" prop is provided as it enables React to remount the component when it changes
              key={selectedElement.id}
              selectedElement={selectedElement}
              importSourceType={s.importSourceType}
            />
          )}
          {isImageElement(selectedElement) && (
            <ImageElementControls
              key={selectedElement.id}
              selectedElement={selectedElement}
            />
          )}
          {isTeamAssetElement(selectedElement) && (
            <TeamAssetElementControls
              key={selectedElement.id}
              selectedElement={selectedElement}
            />
          )}
          {isVectorElement(selectedElement) && (
            <VectorElementControls
              key={selectedElement.id}
              selectedElement={selectedElement}
            />
          )}
        </Flex>
        <Box alignSelf={"flex-end"}></Box>
      </Flex>
    </Flex>
  );
};

const DeleteBtn = forwardRef<HTMLButtonElement, { onClick: () => void }>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        px={2}
        colorScheme='red'
        onClick={props.onClick}
        data-testid='deleteElement'
        size='sm'
      >
        <Icon mr={1} as={FaTrashAlt} />
        Delete
      </Button>
    );
  }
);

DeleteBtn.displayName = "DeleteBtn";

export default Rightsidebar;
