import { FC } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

export interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  warning: string;
  title?: string;
}

export const WarningModal: FC<WarningModalProps> = ({
  isOpen,
  onClose,
  warning,
  title = "Save"
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} id='warningModal'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{warning}</ModalBody>
        <ModalFooter>
          <Button colorScheme='gray' onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
