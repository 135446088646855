import { FC, useCallback, useEffect, useMemo, useRef } from "react";

import { AdElementText } from "@adflow/types";
import { updateTextSize } from "auto-text-size";
import { useProgress } from "../../ProgressContext";
import { useWaitForFontLoad } from "../../useWaitForFontLoad";
import { buildCssGradientFromFigmaFills } from "./buildGradientFromFills";

type Props = {
  id: string;
  elData: AdElementText["data"];
  position: AdElementText["position"];
  size: AdElementText["size"];
  sources: AdElementText["sources"];
};

const TextElement: FC<Props> = ({ id, elData, position, size, sources }) => {
  const { style, content, dynamicFont, isDate, isCountdown } = elData;
  return (
    <div
      data-isdate={isDate}
      data-iscountdown={isCountdown}
      data-id={id}
      style={{
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        position: "absolute",
        display: "flex",
        // we can expect the flex-direction to always be row for text
        // so text align will match the justifyContent values.
        justifyContent: style.textAlign ?? undefined,
        textTransform: style.textCase ?? undefined,
        alignItems: "center",
        zIndex: position.z
      }}
    >
      {dynamicFont?.enabled ? (
        <AutoFitText
          id={id}
          maxFontSize={dynamicFont.maxFontSize}
          minFontSize={dynamicFont.minFontSize}
          lineHeight={dynamicFont.lineHeight}
          multiLine={dynamicFont.multiLine}
          size={size}
          content={content}
          style={style}
          sources={sources}
        />
      ) : (
        <StaticText id={id} content={content} style={style} />
      )}
    </div>
  );
};

TextElement.displayName = "TextElement";

export default TextElement;

type StaticTextProps = {
  id: string;
  style: AdElementText["data"]["style"];
  content: string;
};

const StaticText: FC<StaticTextProps> = ({ id, style, content }) => {
  const { updateElementReadyState } = useProgress();
  useWaitForFontLoad(
    {
      fontFamily: style.fontFamily,
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle
    },
    () => {
      updateElementReadyState(id, true);
    }
  );

  return (
    <span
      style={{
        position: "absolute",
        color: style.color ?? undefined,
        fontFamily: `'${style.fontFamily}'` ?? undefined,
        fontWeight: style.fontWeight ?? undefined,
        textAlign: style.textAlign ?? undefined,
        fontStyle: style.fontStyle ?? undefined,
        textRendering: "geometricPrecision",
        lineHeight: `${style.lineHeight}px`,
        fontSize: `${style.fontSize}px`,
        transform: style.transform ?? undefined,
        WebkitTextStroke:
          (style.stroke &&
            `${style.strokeWeight ? style.strokeWeight : 1}px ${
              style.stroke
            }`) ??
          undefined,
        textDecoration: `${style.textDecoration} from-font`,
        textShadow: style.textShadow ?? undefined,
        ...buildCssGradientFromFigmaFills(style.fills)
      }}
    >
      {content}
    </span>
  );
};

StaticText.displayName = "StaticText";

type AutoFitTextProps = {
  id: string;
  maxFontSize: number;
  minFontSize: number;
  lineHeight: number;
  multiLine: boolean;
  content: string;
  style: AdElementText["data"]["style"];
  size: AdElementText["size"];
  sources: AdElementText["sources"];
};

const AutoFitText: FC<AutoFitTextProps> = ({
  id,
  content,
  minFontSize,
  maxFontSize,
  multiLine,
  style,
  size,
  sources
}) => {
  const containerEl = useRef<HTMLDivElement>(null);
  const innerEl = useRef<HTMLDivElement>(null);
  const fontSizePrecisionPx = 0.1;
  const { updateElementReadyState } = useProgress();

  const syncHomeAway = useMemo(() => {
    if (sources[0]?.name === "HOME_NAME" || sources[0]?.name === "AWAY_NAME") {
      return true;
    }

    return false;
  }, [sources]);

  const resizeText = useCallback(() => {
    if (!containerEl.current || !innerEl.current) {
      return;
    }

    updateTextSize({
      innerEl: innerEl.current,
      containerEl: containerEl.current,
      mode: multiLine ? "box" : "boxoneline",
      minFontSizePx: minFontSize,
      maxFontSizePx: maxFontSize,
      fontSizePrecisionPx
    });
  }, [maxFontSize, minFontSize, multiLine]);

  useWaitForFontLoad(
    {
      fontFamily: style.fontFamily,
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle
    },
    () => {
      // Text fit should only be calculated once the font is loaded
      resizeText();
      updateElementReadyState(id, true);
    }
  );

  useEffect(() => {
    resizeText();
  }, [content, style, size, resizeText]);

  return (
    <div
      ref={containerEl}
      style={{
        // autoTextSize sets this container to display flex by default
        width: `${size.width}px`,
        height: `${size.height}px`,
        justifyContent: style.textAlign ?? undefined
      }}
    >
      <span
        className='auto-fit-text'
        data-sync-home-away={syncHomeAway}
        data-multi-line={multiLine}
        data-min-font-size={minFontSize}
        data-max-font-size={maxFontSize}
        data-font-size-precision-px={fontSizePrecisionPx}
        ref={innerEl}
        style={{
          color: style.color ?? undefined,
          transform: style.transform ?? undefined,
          fontFamily: `'${style.fontFamily}'` ?? undefined,
          fontWeight: style.fontWeight ?? undefined,
          fontStyle: style.fontStyle ?? undefined,
          textRendering: "geometricPrecision",
          lineHeight:
            (style.lineHeight && `${style.lineHeight}px`) ?? undefined,
          alignSelf: "center",
          textAlign: style.textAlign ?? undefined,
          WebkitTextStroke:
            (style.stroke &&
              `${style.strokeWeight ? style.strokeWeight : 1}px ${
                style.stroke
              }`) ??
            undefined,
          textDecoration: `${style.textDecoration} from-font`,
          textShadow: style.textShadow ?? undefined,
          ...buildCssGradientFromFigmaFills(style.fills)
        }}
      >
        {content}
      </span>
    </div>
  );
};

AutoFitText.displayName = "AutoFitText";

export { AutoFitText, StaticText };
