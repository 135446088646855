import { Typography, Divider, Box, Link } from "@mui/material";
import { useState, useMemo, useCallback } from "react";
import {
  useLocation,
  useNavigate,
  NavLink as ReactRouterLink
} from "react-router-dom";
import NavbarUserDropdown from "../../NavUserDropdown/NavUserDropdown";
import { DeleteModal } from "./deleteModal";
import { LazyImage } from "./LazyImage";
import thumbnailFallback from "./thumbnail_fallback.png";

type ListItem = {
  id: string;
  name: string;
  thumbnail: string;
};

function TemplateCard({
  item,
  handleDelete
}: {
  item: ListItem;
  handleDelete: (id: string) => Promise<boolean>;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const query = useMemo(() => {
    return location.search ? `${location.search}` : "";
  }, [location]);

  const menuItems = [
    {
      name: "Duplicate",
      templateId: item.id,
      action: () => {
        navigate(`duplicate/${item.id}${query}`, {
          state: {
            thumbnail: item.thumbnail
          }
        });
      }
    },
    {
      name: "Delete",
      templateId: item.id,
      action: () => {
        setModalOpen(true);
      }
    }
  ];

  const confirmDelete = async (id: string) => {
    const success = await handleDelete(id);
    if (success) {
      handleClose();
    } else {
      setErrorMessage("Failed to delete template");
    }
  };

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setErrorMessage("");
  }, []);

  return (
    <Box
      data-testid={`template-${item.name}`}
      height={"fit-content"}
      width={"min-content"}
      sx={{
        borderWidth: 2,
        borderRadius: "4px",
        borderStyle: "solid",
        borderColor: "mcGrey"
      }}
    >
      <Box justifyContent='space-between' display={"flex"}>
        <Typography
          height='40px'
          variant='body2'
          paddingX={2}
          alignContent='center'
          sx={{
            textDecoration: "none",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            lineClamp: 2
          }}
        >
          {item.name}
        </Typography>
        <NavbarUserDropdown testId='templateActions' menuItems={menuItems} />
      </Box>
      <Divider />
      <Link
        component={ReactRouterLink}
        to={`edit/${item.id}${query}`}
        data-testid='editTemplate'
        variant='body2'
        underline='none'
        color='#1F1F1F'
      >
        <Box width={"300px"} height={"300px"} p={2}>
          <LazyImage
            src={item.thumbnail || thumbnailFallback}
            alt={item.name}
            boxSize='268px'
            objectFit='contain'
            borderRadius={"3px"}
          />
        </Box>
      </Link>

      <DeleteModal
        open={modalOpen}
        onClose={handleClose}
        onConfirm={() => confirmDelete(item.id)}
        errorMessage={errorMessage}
      />
    </Box>
  );
}

export { TemplateCard };
