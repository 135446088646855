import { FC, useCallback, useMemo } from "react";

import { FormControl, FormHelperText } from "@chakra-ui/react";
import {
  Mention,
  MentionItem,
  MentionsInput,
  OnChangeHandlerFunc,
  SuggestionDataItem
} from "react-mentions";
import classNames from "../DynamicStringInput/index.module.css";
const trigger = /({([^{]*))$/;
// Some customisation is only possible using inline styles
const inlineStyles = {
  suggestions: {
    marginTop: 20,
    background: "white",
    list: {
      maxHeight: 360,
      overflow: "auto"
    },
    item: {
      "&sourceName": {
        color: "gray",
        fontSize: "0.8em"
      }
    }
  }
};

type Suggestion = SuggestionDataItem & {
  sourceTypeShort?: string;
  sourceName?: string;
};

interface Props {
  suggestions: Array<Suggestion>;
  value: string;
  onChange: (newValue: string, data: Array<MentionItem>) => void;
}

export const LabeledMultiDynamicStringInput: FC<Props> = ({
  value,
  onChange,
  suggestions
}) => {
  const handleChange: OnChangeHandlerFunc = useCallback(
    (e, newValue, newPlainTextValue, mentions) => {
      onChange(newValue, mentions);
    },
    [onChange]
  );

  const creativeHubMfClassName = useMemo(
    () => classNames["creative-hub-mf"] || "",
    []
  );

  return (
    <FormControl className={creativeHubMfClassName}>
      <MentionsInput
        data-testid='content'
        className='mentions'
        classNames={classNames}
        singleLine
        value={value}
        onChange={handleChange}
        style={inlineStyles}
      >
        <Mention
          markup='{__id__}'
          trigger={trigger}
          data={suggestions}
          renderSuggestion={(
            suggestion: Suggestion,
            search,
            highlightedDisplay
          ) => (
            <>
              {highlightedDisplay} {"-"}
              <span
                style={{
                  fontSize: "12px",
                  border: "1px solid gray",
                  padding: "2px",
                  borderRadius: "10px",
                  marginLeft: "5px"
                }}
              >
                {suggestion.sourceName}
              </span>
            </>
          )}
          displayTransform={id => {
            // TODO: move this? --> put ID transformation
            // in one place
            const parts = id.split(":");
            const sourceType = suggestions.find(
              s => s.id === id
            )?.sourceTypeShort;

            if (parts.length < 2) {
              return "~";
            }
            if (sourceType === "") {
              return `{${parts[1]}}`;
            }
            return `{${parts[1]}-${sourceType}}`;
          }}
        />
      </MentionsInput>
      <FormHelperText>
        {'Type "{" to use a variable in your text.'}
      </FormHelperText>
    </FormControl>
  );
};
