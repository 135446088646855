//usefull links:
//https://www.figma.com/widget-docs/api/type-GradientPaint/
//https://github.com/Nushaine/calculateLinearGradient-FigmaAPI/blob/main/linearGradient.ts#L82
//https://forum.figma.com/ask-the-community-7/how-to-convert-figma-gradient-to-css-gradient-7596
//https://github.com/figma-plugin-helper-functions/figma-plugin-helpers/tree/9dc7b9843a2185b81011df0278e2fcd68b4c2f12
//https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/linear-gradient

interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

interface GradientStop {
  position: number; // Position along gradient (0-1)
  color: Color;
}

interface Point {
  x: number;
  y: number;
}


interface FigmaGradient {
  type: "GRADIENT_LINEAR" | "GRADIENT_RADIAL" | "GRADIENT_ANGULAR" | "GRADIENT_DIAMOND";
  gradientStops: GradientStop[];
  gradientHandlePositions: Point[]; // Control points defining gradient direction
}

/**
 * Uses handle positions to calculate the angle of the gradient
 */
function convertFigmaGradientToCss(gradient: FigmaGradient): string {
  const handles = gradient.gradientHandlePositions;

  const start = handles[0];
  const end = handles[1];
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const rad = Math.atan2(dy, dx);
  const deg = rad * (180 / Math.PI);

  // Convert to CSS angle format (CSS 0deg points up, Figma's coordinates are different)
  const cssAngle = (90 - deg + 360) % 360;

  const stops = convertGradientStopsIntoCssColorStop(gradient.gradientStops);

  return `linear-gradient(${cssAngle}deg, ${stops.join(", ")})`;
}

/**
 * Converts a Figma radial gradient to CSS format
 */
function convertRadialGradientToCss(gradient: FigmaGradient): string {
  const stops = convertGradientStopsIntoCssColorStop(gradient.gradientStops);

  return `radial-gradient(circle, ${stops.join(", ")})`;
}

/**
 * Converts a Figma angular gradient to CSS format
 */
function convertAngularGradientToCss(gradient: FigmaGradient): string {
  // For angular gradients, convert position to degrees (0-360)
  const stops = gradient.gradientStops.map((stop) => {
    const color = formatColor(stop.color);
    const positionDeg = Math.round(stop.position * 360);
    return `${color} ${positionDeg}deg`;
  });

  return `conic-gradient(from 0deg, ${stops.join(", ")})`;
}

/**
 * Converts gradient stops into CSS-friendly color-stop strings
 *
 * For each gradient stop:
 * - Changes the stop position from a number between 0 and 1 into a percentage (0% to 100%)
 * - Formats the color as a hex value if fully opaque, or as an rgba string if it has transparency
 *
 * Example output:
 *   ["#ff0000 0%", "rgba(0, 0, 255, 0.5) 50%", "#00ff00 100%"]
 */
function convertGradientStopsIntoCssColorStop(stops: GradientStop[]): string[] {
  return stops.map((stop) => {
    const color = formatColor(stop.color);
    const positionPercent = Math.round(stop.position * 100);
    return `${color} ${positionPercent}%`;
  });
}

/**
 * Formats a color to CSS hex or rgba string
 */
function formatColor(color: Color): string {
  const r = Math.round(color.r * 255);
  const g = Math.round(color.g * 255);
  const b = Math.round(color.b * 255);

  if (color.a === 1) {
    // Use hex format for solid colors
    return "#" +
      r.toString(16).padStart(2, "0") +
      g.toString(16).padStart(2, "0") +
      b.toString(16).padStart(2, "0");
  } else {
    // Use rgba for transparent colors
    return `rgba(${r}, ${g}, ${b}, ${color.a})`;
  }
}

export function buildCssGradientFromFigmaFills(fills: any[] | undefined | null): Record<string, string> {
  if (!fills || !fills.length) {
    return {};
  }

  const gradients = fills.filter(fill =>
    fill.type === 'GRADIENT_LINEAR' ||
    fill.type === 'GRADIENT_RADIAL' ||
    fill.type === 'GRADIENT_ANGULAR'
  );

  if (gradients.length === 0) {
    return {};
  }

  const gradientCssArray = gradients.map(gradient => {
    switch (gradient.type) {
      case 'GRADIENT_LINEAR':
        return convertFigmaGradientToCss(gradient);
      case 'GRADIENT_RADIAL':
        return convertRadialGradientToCss(gradient);
      case 'GRADIENT_ANGULAR':
        return convertAngularGradientToCss(gradient);
      default:
        return '';
    }
  }).filter(css => css !== '');

  const combinedGradients = gradientCssArray.join(', ');

  return {
    backgroundClip: "text",
    backgroundImage: combinedGradients,
    color: 'transparent'
  };
}
