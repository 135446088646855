import React, { FC, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage
} from "@chakra-ui/react";

interface ValidatedInputProps {
  label: string;
  placeholder?: string;
  type?: string;
  validate: (value: string) => string | null; // Returns an error message if invalid, or null if valid
  onChange?: (value: string) => void;
  "data-testid"?: string;
}

const ValidatedInput: FC<ValidatedInputProps> = ({
  label,
  placeholder,
  type = "text",
  validate,
  onChange,
  "data-testid": testId
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    const validationError = validate(newValue);
    setError(validationError);
    onChange && onChange(newValue);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        data-testid={testId}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

ValidatedInput.displayName = "ValidatedInputComponent";

export default ValidatedInput;
