import { FC } from "react";

import { LuType } from "react-icons/lu";

import { AdElementText, AdTemplate } from "@adflow/types";
import { Flex, HStack, Heading, Icon } from "@chakra-ui/react";

import DynamicFontSizeControls from "./DynamicFontSizeControls";
import TextCaseControls from "./TextCaseControls";
import TextAlignmentControls from "./TextAlignmentControls";
import LineHeightInput from "./LineHeightInput";
import FontSelectionControls from "./FontSelectionControls";
import DynamicContentTemplateInput from "../DynamicContentTemplateInput";
import DateTimeControls, { isDateTimePresent } from "./DateTimeControls";

type Props = {
  selectedElement: AdElementText;
  importSourceType: AdTemplate["importSourceType"];
};

const TextElementControls: FC<Props> = ({
  selectedElement,
  importSourceType
}) => {
  return (
    <Flex direction='column'>
      <Flex alignItems='center' mb='6'>
        <Icon as={LuType} fontSize='xl' mr='2' />
        <Heading as='h2' size='md' noOfLines={1}>
          {selectedElement.name}
        </Heading>
      </Flex>

      <DynamicContentTemplateInput selectedElement={selectedElement} />
      {/* Fonts and Typography are not used in creatomate templates */}
      {importSourceType !== "creatomate" && (
        <>
          <DynamicFontSizeControls textElement={selectedElement} />
          <Heading fontSize='lg' fontWeight='semibold' mt='7' noOfLines={1}>
            Typography
          </Heading>
          <HStack spacing='48px' mt='5'>
            <TextAlignmentControls selectedElement={selectedElement} />
            <LineHeightInput selectedElement={selectedElement} />
          </HStack>
          <FontSelectionControls selectedElement={selectedElement} />
          <TextCaseControls selectedElement={selectedElement} />
        </>
      )}
      {isDateTimePresent(selectedElement) && (
        <DateTimeControls selectedElement={selectedElement} />
      )}
    </Flex>
  );
};

TextElementControls.displayName = "TextElementControls";

export default TextElementControls;
