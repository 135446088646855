import { Modal, Typography, Box, Button } from "@mui/material";

function DeleteModal({
  open,
  onClose,
  onConfirm,
  errorMessage
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  errorMessage: string;
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "none",
          borderRadius: "4px",
          boxShadow: 24,
          p: 3
        }}
      >
        <Typography variant='h1' component='h2'>
          Confirm Delete
        </Typography>
        <Typography variant='body1' sx={{ pt: 2 }}>
          Are you sure you want to delete this template? This action cannot be
          undone.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
            gap: 2
          }}
        >
          {errorMessage && (
            <Typography variant='body1' color='error'>
              {errorMessage}
            </Typography>
          )}

          <Button
            onClick={onClose}
            variant='contained'
            data-testid='cancel'
            color='secondary'
          >
            Cancel
          </Button>

          <Button
            onClick={onConfirm}
            variant='contained'
            data-testid='confirm-delete'
            color='error'
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export { DeleteModal };
