import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import {
  AdElement,
  AdElementImage,
  AdElementTeamAsset,
  AdElementText,
  AdflowAPI,
  isImageElement,
  isTeamAssetElement,
  isTextElement,
  Creatomate
} from "@adflow/types";
import { Skeleton } from "@chakra-ui/react";
import { Preview } from "@creatomate/preview";

import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useMappedElements } from "../previewutils";
import { AWAY_JERSEY, HOME_JERSEY } from "./jersey";

type SupportedElements = AdElementText | AdElementImage | AdElementTeamAsset;

const CREATOMATE_IMAGE_CDN_PREFIX = "https://cdn.creatomate.com/files/";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  source: string; // the JSON source of a og creatomate template
  elements: Array<AdElement>;
  displayOptions?: AdflowAPI.AdTemplateDisplayOptions | null;
  brand?: ConsoleAPIGraph.Brand | null;
}

export const CreatomatePreview: FC<Props> = ({
  source,
  elements,
  displayOptions,
  brand,
  style
}) => {
  const previewRef = useRef(null);
  const previewInstance = useRef<Preview | null>(null);
  const [isLoadingPreview, setIsLoadingPreview] = useState(true);
  const parsedSource = useMemo(() => {
    if (source) {
      return prefixImageURLs(source);
    }
    return {};
  }, [source]);

  const mappedElements = useMappedElements(elements, displayOptions, brand);

  const modifications = useMemo(() => {
    const filteredElements = filterElements(mappedElements);
    return mapModifications(filteredElements);
  }, [mappedElements]);

  useEffect(() => {
    if (previewRef.current && !previewInstance.current) {
      const preview = new Preview(
        previewRef.current,
        "player",
        "public-b0xkd0kd3fyeen70pflgz5th" //public token - NOT THE API KEY
      );

      preview.onReady = async () => {
        previewInstance.current = preview;
        await preview.setSource(parsedSource);
        await preview.setModifications(modifications);
        setIsLoadingPreview(false);
      };
    }
  }, [parsedSource, modifications]);

  useEffect(() => {
    if (previewInstance.current) {
      previewInstance.current.setModifications(modifications);
    }
  }, [previewInstance, modifications]);

  useEffect(() => {
    if (previewInstance.current) {
      previewInstance.current.setSource(parsedSource);
    }
  }, [previewInstance, parsedSource]);

  return (
    <>
      {isLoadingPreview && (
        <Skeleton
          width={style?.width ?? "600px"}
          height={style?.height ?? "600px"}
        ></Skeleton>
      )}
      <div
        ref={previewRef}
        style={{
          position: "relative",
          width: "600px",
          height: "600px",
          transformOrigin: "left top",
          display: isLoadingPreview ? "none" : "inherit",
          ...style
        }}
      ></div>
    </>
  );
};

function isSupportedElement(el: AdElement): el is SupportedElements {
  return isTextElement(el) || isImageElement(el) || isTeamAssetElement(el);
}

function filterElements(elements: Array<AdElement>): Array<SupportedElements> {
  return elements.filter(isSupportedElement);
}

type Modifications = Record<string, string>;

function mapModifications(elements: Array<SupportedElements>): Modifications {
  const modifications: Modifications = {};
  elements.forEach(async e => {
    if (isTextElement(e)) {
      modifications[e.id + ".text"] = e.data.content;
    }
    if (isTeamAssetElement(e)) {
      modifications[e.id + ".source"] = teamAssetGetter(e);
    }
  });
  return modifications;
}

function teamAssetGetter(element: AdElementTeamAsset): string {
  if (!element.data.display) {
    return "";
  }
  if (element.data.display.type === "badge") {
    return element.data.display.data.content;
  } else if (element.data.display.type === "kit") {
    if (element.data.source === "AWAY_ASSET") {
      return HOME_JERSEY;
    }
    if (element.data.source === "HOME_ASSET") {
      return AWAY_JERSEY;
    }
    if (!element.data.source) {
      return HOME_JERSEY;
    }
  }
  return "";
}

export default CreatomatePreview;

// Helper function to process elements recursively
function processElements(
  elements: Creatomate.CreatomateImportElement[]
): Creatomate.CreatomateImportElement[] {
  return elements.map(element => {
    if (element.type === "image" && element.source) {
      // Only prefix if the URL doesn't already have the prefix
      if (element.source.startsWith("http")) {
        element.source = `${CREATOMATE_IMAGE_CDN_PREFIX}${element.source}`;
      }
    }

    // Recursively process nested elements if they exist
    if (element.elements) {
      element.elements = processElements(element.elements);
    }

    return element;
  });
}

/* the function takes in the source;
 * it finds all elements with type image. if the source is a url,
 *it will be changed to prefix with https://cdn.creatomate.com/files/, and then it returns the source string
 * this is needed to circumvent CORS
 */
function prefixImageURLs(
  source: string
): Creatomate.CreatomateSourceProperties {
  const data = JSON.parse(source);
  try {
    // Process all elements in the template
    if (data.elements) {
      data.elements = processElements(data.elements);
    }

    return data;
  } catch (error) {
    console.error("Error processing source:", error);
    return data;
  }
}
