// src/toSlug.ts
function toSlug(str) {
  let s = str;
  if (!s) {
    return "";
  }
  s = s.toLowerCase().trim();
  s = s.replace(/ & /g, " and ");
  s = s.replace(/[ ]+/g, "-");
  s = s.replace(/[-]+/g, "-");
  s = s.replace(/[^a-z0-9-]+/g, "");
  return s;
}

// src/useIsomorphicLayoutEffect.tsx
import { useEffect, useLayoutEffect } from "react";
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

// src/usePrevious.tsx
import * as React from "react";
function usePrevious(value) {
  const ref = React.useRef(value);
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// src/useGenericContext.tsx
import { createContext, useContext } from "react";
var createGenericContext = (defaultContext) => {
  const genericContext = createContext(defaultContext);
  const useGenericContext = () => {
    const contextIsDefined = useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error("useGenericContext must be used within a Provider");
    }
    return contextIsDefined;
  };
  return [useGenericContext, genericContext.Provider];
};

// src/useObjectState.ts
import { useState } from "react";
var useObjectState = (initialState) => {
  const [state, _setState] = useState(initialState);
  const setState = (newState) => {
    _setState((prev) => ({ ...prev, ...newState }));
  };
  return [state, setState];
};

// src/usePotentialWinnings.ts
function getPotentialWinnings(odds, stake, currency, language) {
  const decimalOdds2 = odds[0] / odds[1] + 1;
  const winnings = Math.floor(stake * decimalOdds2);
  return formatCurrency(currency, winnings, false, language);
}
function formatCurrency(code, number, forceUseCurrencySymbol, language) {
  let formattedParts;
  try {
    formattedParts = new Intl.NumberFormat(language, {
      style: "currency",
      currency: code,
      minimumFractionDigits: 0,
      currencyDisplay: "narrowSymbol",
      notation: "compact",
      compactDisplay: "short",
      maximumSignificantDigits: 3
    }).formatToParts(number);
  } catch (err) {
    formattedParts = new Intl.NumberFormat(language, {
      style: "currency",
      currency: code,
      minimumFractionDigits: 0,
      currencyDisplay: "symbol",
      maximumSignificantDigits: 3
    }).formatToParts(number);
  }
  const formatted = formattedParts.map((formattedPart) => {
    const { type, value } = formattedPart;
    const shouldIgnoreCurrency = type === "currency" && value.length >= 3 && !forceUseCurrencySymbol;
    if (shouldIgnoreCurrency) {
      return "";
    }
    return value;
  }).join("").trim();
  return formatted;
}

// src/formatOdds.ts
function formatOdds(odds, format, stake, currency, language, oddsAmerican) {
  switch (format) {
    case "decimalOdds": {
      return decimalOdds(odds);
    }
    case "europeanDecimalOdds": {
      return europeanDecimalOdds(odds);
    }
    case "americanOdds": {
      return americanOdds(odds, oddsAmerican);
    }
    case "potentialWinnings": {
      return potentialWinnings(odds, currency, stake, language);
    }
    case "impliedProbability": {
      return impliedProbability(odds);
    }
    default: {
      return fractionalOdds(odds);
    }
  }
}
function decimalOdds(odds) {
  if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  }
  const decimal = odds[0] / odds[1] + 1;
  return String(decimal.toFixed(2));
}
function europeanDecimalOdds(odds) {
  if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  }
  const decimal = odds[0] / odds[1] + 1;
  return decimal.toFixed(2).replace(".", ",");
}
function americanOdds(odds, oddsAmerican) {
  if (oddsAmerican && oddsAmerican.length > 0) {
    return oddsAmerican;
  } else if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  } else if (odds[0] > odds[1]) {
    const over = odds[0] / odds[1] * 100;
    return `+${String(Math.round(over))}`;
  } else if (odds[0] < odds[1]) {
    const under = -100 / (odds[0] / odds[1]);
    return String(Math.round(under));
  } else {
    return "+100";
  }
}
function potentialWinnings(odds, currency, stake, language) {
  if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  }
  stake = stake || 10;
  currency = currency || "GBP";
  language = language || "en-GB";
  return getPotentialWinnings(odds, stake, currency, language);
}
function impliedProbability(odds) {
  if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  }
  const percentage = 1 / (odds[0] / odds[1] + 1) * 100;
  return `${Number(percentage.toFixed(1))}%`;
}
function fractionalOdds(odds) {
  if (!isValidOdds(odds)) {
    return fallbackDisplayOdds(odds);
  }
  return odds[0] + "/" + odds[1];
}
function isValidOdds(odds) {
  return odds[0] > 0 && odds[1] > 0;
}
function fallbackDisplayOdds(odds) {
  if (odds[0] === 0 && odds[1] === 0) {
    return "-";
  } else if (odds[0] < 0 || odds[1] < 0) {
    return "SP";
  }
  return "";
}

// src/useEffectOnce.tsx
import { useEffect as useEffect3 } from "react";
function useEffectOnce(effect) {
  useEffect3(effect, []);
}

// src/useInterval.tsx
import { useRef as useRef2, useEffect as useEffect4 } from "react";
function useInterval(callback, delay) {
  const savedCallback = useRef2(callback);
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect4(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
}

// src/indexObjArray.ts
function indexObjArray(arr) {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

// src/uniqArray.ts
function uniqArray(value, index, arr) {
  return arr.indexOf(value) === index;
}

// src/formatDateTime.ts
var TimeFormat = /* @__PURE__ */ ((TimeFormat2) => {
  TimeFormat2["LONG"] = "long";
  TimeFormat2["SHORT"] = "short";
  return TimeFormat2;
})(TimeFormat || {});
function fmtReference(date, locale = "en-GB", timeZone = "Etc/UTC", type, option) {
  switch (type) {
    case "DAY":
      return fmtDay(date, locale, timeZone, option.dayFormat);
    case "MONTH":
      return fmtMonth(date, locale, timeZone, option.monthFormat);
    case "TIME":
      return fmtTime(
        date,
        locale,
        timeZone,
        option.timeFormat === "hour12",
        option.showTimezone
      );
    case "COUNTDOWN":
      return fmtCountdown(date, option);
    case "DATE":
    default:
      return fmtDate(date, locale, timeZone);
  }
}
function fmtDate(date, locale, timeZone = "Etc/UTC") {
  const day = date.toLocaleDateString(locale, {
    day: "numeric",
    timeZone
  });
  return `${day}`;
}
function fmtDay(date, locale, timeZone = "Etc/UTC", weekday = "long" /* LONG */) {
  const day = date.toLocaleDateString(locale, {
    weekday,
    timeZone
  });
  return day.at(0)?.toUpperCase() + day.slice(1);
}
function fmtCountdown(date, format) {
  const showDays = format.countdownShowDays;
  const daysSeparator = format.countdownDaysSeparator ?? "d";
  const now = /* @__PURE__ */ new Date();
  const diff = date.getTime() - now.getTime();
  if (diff <= 0) {
    return "LIVE";
  }
  const SEC = 1e3, MIN = 60 * SEC, HRS = 60 * MIN, DAYS = 24 * HRS;
  const days = showDays ? Math.floor(diff / DAYS) : 0;
  const hrs = Math.floor(diff / HRS) - days * 24;
  const min = Math.floor(diff % HRS / MIN).toLocaleString("en-US", {
    minimumIntegerDigits: 2
  });
  const sec = Math.floor(diff % MIN / SEC).toLocaleString("en-US", {
    minimumIntegerDigits: 2
  });
  const daysToShow = showDays && days > 0 ? `${days} ${daysSeparator} ` : "";
  return `${daysToShow}${hrs}:${min}:${sec}`;
}
function fmtTime(date, locale, timeZone = "Etc/UTC", hour12 = true, showTimezone = false) {
  let options = {
    hour: "numeric",
    minute: "2-digit",
    timeZone,
    hour12
  };
  if (showTimezone) {
    options = {
      ...options,
      timeZoneName: "short"
    };
  }
  const time = date.toLocaleTimeString(locale, options);
  return time;
}
function fmtMonth(date, locale, timeZone = "Etc/UTC", monthFormat = "long" /* LONG */) {
  const month = date.toLocaleDateString(locale, {
    month: monthFormat,
    timeZone
  });
  return month.at(0)?.toUpperCase() + month.slice(1);
}

// src/feature-flag-context.tsx
import React2, {
  useState as useState2,
  useContext as useContext2
} from "react";
import { jsx } from "react/jsx-runtime";
var FeatureFlagContext = React2.createContext({
  flags: {
    useMaterialTheme: false,
    allowFontUpload: false
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFlags: () => {
  }
});
var FeatureFlagProvider = ({
  children
}) => {
  const params = new URLSearchParams(window.location.search);
  const useMaterialTheme = params.get("useMaterialTheme") === "true";
  const allowFontUpload = params.get("allowFontUpload") === "true";
  const [flags, setFlags] = useState2({
    useMaterialTheme,
    allowFontUpload
  });
  return /* @__PURE__ */ jsx(FeatureFlagContext.Provider, { value: { flags, setFlags }, children });
};
function useFeatureFlags() {
  const context = useContext2(FeatureFlagContext);
  if (context === void 0) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider"
    );
  }
  return context;
}

// src/parseFonts.ts
var parseFontWeight = (fontWeight) => {
  const fontWeightLowerCase = fontWeight.toLowerCase();
  if (fontWeightLowerCase.includes("thin") || fontWeightLowerCase.includes("hairline")) {
    return 100;
  }
  if (fontWeightLowerCase.includes("extra light") || fontWeightLowerCase.includes("ultra light")) {
    return 200;
  }
  if (fontWeightLowerCase.includes("light")) {
    return 300;
  }
  if (fontWeightLowerCase.includes("normal") || fontWeightLowerCase.includes("regular")) {
    return 400;
  }
  if (fontWeightLowerCase.includes("medium")) {
    return 500;
  }
  if (fontWeightLowerCase.includes("semi bold") || fontWeightLowerCase.includes("demi bold")) {
    return 600;
  }
  if (fontWeightLowerCase.includes("extra bold") || fontWeightLowerCase.includes("ultra bold")) {
    return 800;
  }
  if (fontWeightLowerCase.includes("bold")) {
    return 700;
  }
  if (fontWeightLowerCase.includes("extra black") || fontWeightLowerCase.includes("ultra black")) {
    return 950;
  }
  if (fontWeightLowerCase.includes("black") || fontWeightLowerCase.includes("heavy")) {
    return 900;
  }
  return 400;
};
var parseFontStyle = (str) => {
  const strLower = str.toLowerCase();
  if (strLower.includes("normal")) {
    return "normal";
  }
  if (strLower.includes("italic")) {
    return "italic";
  }
  if (strLower.includes("oblique")) {
    return "oblique";
  }
  return "normal";
};
function notEmpty(value) {
  return value !== null && value !== void 0;
}
var parseFonts = (fonts) => {
  return fonts.flatMap((item) => {
    const fontFamily = item?.name.trim() ?? "";
    return item?.fonts?.map((font) => ({
      fontFamily,
      weight: parseFontWeight(font?.variant ?? ""),
      style: parseFontStyle(font?.variant ?? ""),
      src: font?.path ?? ""
    })) ?? [];
  }) ?? [];
};

// src/auth-aws-cognito/cognitoAuth.ts
var CognitoAuth = class {
  tokenEndpoint;
  refreshToken;
  idToken;
  constructor(tokenEndpoint, refreshToken, idToken) {
    if (!tokenEndpoint) {
      throw new Error("tokenEndpoint is not provided");
    }
    if (!refreshToken) {
      throw new Error("refreshToken is not provided");
    }
    if (!idToken) {
      throw new Error("idToken is not provided");
    }
    this.tokenEndpoint = tokenEndpoint;
    this.refreshToken = refreshToken;
    this.idToken = idToken;
  }
  async refreshIDToken(refreshToken, idToken) {
    const body = JSON.stringify({ refreshToken });
    const response = await fetch(this.tokenEndpoint, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${idToken}`,
        "Content-Type": "application/json",
        "Accept": "*/*"
      },
      body
    });
    if (!response.ok) {
      const responseBody = await response.text();
      throw new Error(`failed to refresh token, status: ${response.status}, response: ${responseBody}`);
    }
    return response.json();
  }
  async getToken() {
    const tokenResponse = await this.refreshIDToken(this.refreshToken, this.idToken);
    return tokenResponse.idToken;
  }
};
export {
  CognitoAuth,
  FeatureFlagContext,
  FeatureFlagProvider,
  TimeFormat,
  createGenericContext,
  fmtDate,
  fmtDay,
  fmtMonth,
  fmtReference,
  fmtTime,
  formatCurrency,
  formatOdds,
  indexObjArray,
  notEmpty,
  parseFontStyle,
  parseFontWeight,
  parseFonts,
  toSlug,
  uniqArray,
  useEffectOnce,
  useFeatureFlags,
  useInterval,
  useIsomorphicLayoutEffect,
  useObjectState,
  usePrevious
};
