import { FC } from "react";

import { EditorSidebarButtonProps } from "@adflow/types";
import { IconButton, Image, useDisclosure } from "@chakra-ui/react";
import CreatomateComponentImportModal from "../CreatomateComponentImportModal";
import creatomateIcon from "./creatomate-icon.svg";

const MenuButton: FC<EditorSidebarButtonProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        size='lg'
        px={4}
        aria-label='Search database'
        icon={<Image src={creatomateIcon} />}
        borderRadius='100%'
        aspectRatio='1/1'
        onClick={onOpen}
        data-testid='creatomateImportButton'
      />
      {isOpen && (
        <CreatomateComponentImportModal onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
};

MenuButton.displayName = "CreatomateButton";

export default MenuButton;
