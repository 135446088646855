import { useState, FC } from "react";
import "./index.css";
import { Checkbox, Stack, Button, Input, Text } from "@chakra-ui/react";

import { persistToSessionStorage } from "../../models/User";
import { IMarketingCloudUser, MarketingCloudUser } from "@ads/iam-library";

const UserControlForm: FC<{ user: IMarketingCloudUser | null }> = ({
  user
}) => {
  const [admin, setAdmin] = useState(
    user?.getRoles()?.includes("sr-admin") || false
  );

  const isPolicySet = (
    policies: string[][] | undefined,
    policyName: string
  ) => {
    if (!policies) return false;
    return policies[0][1].includes(policyName);
  };

  const [create, setCreate] = useState(
    isPolicySet(user?.getPolicies(), "create")
  );
  const [edit, setEdit] = useState(isPolicySet(user?.getPolicies(), "edit"));
  const [read, setRead] = useState(isPolicySet(user?.getPolicies(), "read"));
  const [holdingId, setHoldingId] = useState(
    user?.getHoldingIds()?.[0]?.toString() || ""
  );

  const save = () => {
    let role = "non-admin";
    let policy = ["non-admin", "", `srn:${holdingId}::*`];
    const permissions = [];
    if (admin) {
      role = "sr-admin";
      policy = ["sr-admin", ".*", "srn:*"];
    } else {
      if (create) permissions.push("create");
      if (edit) permissions.push("edit");
      if (read) permissions.push("read");

      if (permissions.length > 0) {
        policy[1] = permissions.join("|");
      }
    }

    const parsedHoldingId = parseInt(holdingId);
    const holdingIds = isNaN(parsedHoldingId) ? [] : [parsedHoldingId];
    const user: IMarketingCloudUser = new MarketingCloudUser({
      cognitoId: "123",
      holdingIds,
      businessEntityIds: [],
      roles: [role],
      policies: [policy],
      allowedApplications: ["creative-hub"]
    });

    persistToSessionStorage(user);
    window.location.reload();
  };

  return (
    <div className='form'>
      <Stack spacing={5} direction='column' color={"red"}>
        <Checkbox isChecked={admin} onChange={e => setAdmin(e.target.checked)}>
          Admin
        </Checkbox>
        <Checkbox
          isChecked={create}
          onChange={e => setCreate(e.target.checked)}
        >
          Create
        </Checkbox>
        <Checkbox isChecked={read} onChange={e => setRead(e.target.checked)}>
          Read
        </Checkbox>
        <Checkbox isChecked={edit} onChange={e => setEdit(e.target.checked)}>
          Edit
        </Checkbox>

        <Text mb='8px'>Holding id:</Text>
        <Input
          type='number'
          placeholder='holding id for ex 1323'
          value={holdingId}
          onChange={e => setHoldingId(e.target.value)}
          border={"black solid"}
          margin={"5px"}
        />
      </Stack>
      <Button onClick={save}>Save & Reload</Button>
    </div>
  );
};
const UserControl: FC<{ user: IMarketingCloudUser | null }> = ({ user }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  return (
    <div className='userControl'>
      {isFormVisible && <UserControlForm user={user} />}
      <button onClick={() => setIsFormVisible(!isFormVisible)}>Set User</button>
    </div>
  );
};

export default UserControl;
