import { AdTemplate } from "@adflow/types";
import {
  Box,
  Button,
  ModalBody,
  ModalFooter,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { useAPIClient } from "../../context/apiClient";
import ValidatedInput from "./validatedInput";

interface Props {
  onClose: () => void;
  onDataFetched: (adTemplate: AdTemplate, thumbnailURL: string) => void;
}

const TEMPLATE_ID_REGEX =
  /^(?:https?:\/\/(?:www\.)?creatomate\.com\/.*?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))$/i;

const ComponentImportStepOne: FC<Props> = ({ onClose, onDataFetched }) => {
  const client = useAPIClient();
  const [templateId, setTemplateId] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [importErrorMessage, setImportErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const validateTemplateIDInput = (value: string): string | null => {
    if (!value.trim()) {
      return "Template ID is required.";
    }

    if (!TEMPLATE_ID_REGEX.test(value)) {
      return "Invalid Template ID or URL.";
    }

    return null;
  };

  const validateApiKeyInput = (value: string): string | null => {
    if (!value.trim()) {
      return "API key is required.";
    }
    if (value.length === 96) {
      return null;
    }
    return "Invalid API key.";
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setImportErrorMessage("");
    try {
      const adTemplateResponse = await client.importCreatomateTemplate({
        templateId: templateId,
        apiKey: apiKey
      });
      const thumbnailURL = await client.createThumbnail({
        templateID: templateId
      });
      onDataFetched(adTemplateResponse, thumbnailURL);
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "Failed to import. Please verify correct ID and API key.";
      console.error("Import failed:", err);
      setImportErrorMessage(errorMessage);
    }

    setLoading(false);
  }, [apiKey, client, onDataFetched, templateId]);

  const isImportEnabled = useMemo(() => {
    const isTemplateIdValid = validateTemplateIDInput(templateId) == null;
    const isApiKeyValid = validateApiKeyInput(apiKey) == null;

    return isTemplateIdValid && isApiKeyValid;
  }, [templateId, apiKey]);

  return (
    <>
      <ModalBody>
        <ValidatedInput
          label='Creatomate Template ID or URL *'
          data-testid='CreatomateTemplateIdInput'
          onChange={value => {
            const match = value.match(TEMPLATE_ID_REGEX);
            if (match) {
              // The ID may be in the first or second capturing group.
              const id = match[1] || match[2];
              if (id) {
                setTemplateId(id);
              }
            } else {
              setTemplateId("");
            }
          }}
          validate={validateTemplateIDInput}
        ></ValidatedInput>
        <Box pt={2}></Box>

        <ValidatedInput
          label='API Key *'
          data-testid='CreatomateApiKeyInput'
          onChange={value => {
            setApiKey(value);
          }}
          validate={validateApiKeyInput}
        ></ValidatedInput>
        {importErrorMessage && (
          <Alert mt={5} status='error'>
            <AlertIcon />
            {importErrorMessage}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid='creatomateCloseButton'
          variant='ghost'
          mr={3}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          colorScheme='blue'
          onClick={handleSubmit}
          isLoading={loading}
          data-testid='creatomateImportButton'
          isDisabled={!isImportEnabled}
        >
          Import
        </Button>
      </ModalFooter>{" "}
    </>
  );
};

ComponentImportStepOne.displayName = "CreatomateImportStepOne";

export default ComponentImportStepOne;
