import { FC, useCallback, useState } from "react";

import { EditorSidebarButtonProps } from "@adflow/types";

import { IconButton, Image, useDisclosure } from "@chakra-ui/react";
import { useEditorStore } from "@adflow/editor";
import sportradarIconUrl from "./sportradar-logo.svg";
import SelectAdvertiser, { SelectAdvertiserProps } from "../SelectAdvertiser";
import { BrandKitGQL } from "@adflow/graphqlgen";
import { useApolloClient } from "@apollo/client";
import { parseFonts } from "@adflow/utils";
import { WarningModal } from "@adflow/editor";
// In the future we will move this modal somewhere else
// It's a little bit weird having the modal open up in this button
const MenuButton: FC<EditorSidebarButtonProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const s = useEditorStore(state => ({
    setBusinessEntityId: state.setBusinessEntityId,
    businessEntityId: state.businessEntityId,
    setCustomFonts: state.setCustomFonts,
    brand: state.brand
  }));
  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState<number>(
    s.businessEntityId || -1
  );
  const [isWarningOpen, setWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const apolloClient = useApolloClient();

  const handleSubmit = useCallback<SelectAdvertiserProps["onSubmit"]>(
    async (selectedAdvertiserId: number) => {
      setSelectedAdvertiserId(selectedAdvertiserId);
      s.setBusinessEntityId(selectedAdvertiserId);

      if (s.brand === null) {
        const { error, data } = await apolloClient.query({
          query: BrandKitGQL.GET_ALL_FONTS_BY_OWNER,
          variables: { advertiserId: JSON.stringify(selectedAdvertiserId) }
        });

        if (error) {
            setWarningMessage(
            "We encountered an issue while fetching fonts from the Brand Kit. Please try again later or contact support if the issue persists."
            );
          setWarningOpen(true);
          onClose();
          return;
        }

        const fonts = data?.getFontFamiliesByOwner
          ? parseFonts(data.getFontFamiliesByOwner)
          : [];
        s.setCustomFonts(fonts);
      }
      onClose();
    },
    [onClose, s]
  );

  return (
    <>
      <IconButton
        size='lg'
        px={4}
        aria-label='MACL button'
        icon={<Image src={sportradarIconUrl} />}
        borderRadius='100%'
        aspectRatio='1/1'
        onClick={onOpen}
        data-testid='maclButton'
      />
      {/* Note: un-mount this stateful component when closed. By default
          this won't be removed from the component tree */}
      {isOpen && (
        <SelectAdvertiser
          selectedAdvertiserId={selectedAdvertiserId}
          isOpen={isOpen}
          handleClose={onClose}
          onSubmit={handleSubmit}
        />
      )}{" "}
      <WarningModal
        isOpen={isWarningOpen}
        onClose={() => setWarningOpen(false)}
        warning={warningMessage}
        title="Warning"
      />
    </>
  );
};

MenuButton.displayName = "MarketingCloudMenuButton";

export default MenuButton;
