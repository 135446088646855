// fontUtils.ts
export function buildAvailableFonts({
  customFonts,
  standardFonts,
  originalFamily,
  originalStyle
}: {
  customFonts: { fontFamily: string; style?: string }[];
  standardFonts: string[];
  originalFamily?: string | null;
  originalStyle: string;
}) {
  const map = new Map<string, Set<string>>();

  // 1) from document.fonts
  document.fonts.forEach(fontFace => {
    if (!map.has(fontFace.family)) {
      map.set(fontFace.family, new Set());
    }
    map.get(fontFace.family)?.add(fontFace.style);
  });

  // 2) customFonts
  customFonts.forEach(f => {
    const fam = f.fontFamily;
    if (!map.has(fam)) {
      map.set(fam, new Set());
    }
    map.get(fam)?.add(f.style || "normal");
  });

  // 3) standardFonts
  standardFonts.forEach(fam => {
    if (!map.has(fam)) {
      map.set(fam, new Set());
    }
    map.get(fam)?.add("normal");
  });

  // 4) original selected
  if (originalFamily && !map.has(originalFamily)) {
    map.set(originalFamily, new Set([originalStyle]));
  } else if (originalFamily) {
    // If already present, make sure the style is included
    map.get(originalFamily)?.add(originalStyle);
  }

  // Turn Map -> array
  const grouped = Array.from(map.entries()).map(([family, styles]) => ({
    family,
    styles: Array.from(styles)
  }));

  return grouped.sort((a, b) => a.family.localeCompare(b.family));
}
