import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { NavLink as ReactRouterLink, useLocation } from "react-router-dom";
import { trpc } from "../utils/apiClient";
import { Button, Divider, Typography, Box, Skeleton } from "@mui/material";
import UserContext from "../../UserProvider/contexts/UserContext";
import { canCreate } from "../utils/permissions";
import { ScopedBusinessEntity } from "@ads/iam-library";
import { TemplateCard } from "../components/templateCard";

type ListItem = {
  id: string;
  name: string;
  thumbnail: string;
};

export function Index() {
  const userContext = useContext(UserContext);
  const [hasCreatePermissions, setHasCreatePermissions] = useState(false);
  const [adTemplates, setAdTemplates] = useState<Array<ListItem>>([]);

  useEffect(() => {
    const checkPermissions = async () => {
      if (!userContext.user) {
        setHasCreatePermissions(false);
        return;
      }
      const result = await canCreate(userContext.user);
      setHasCreatePermissions(result);
    };

    checkPermissions();
  }, [userContext.user]);

  // repass the query received to the next page
  const location = useLocation();
  const query = useMemo(() => location.search || "", [location]);

  const scopedBusinessEntity = new ScopedBusinessEntity();
  const selectedBusinessEntityId: string | null = scopedBusinessEntity.getId();
  const deleteMutation = trpc.adTemplate.delete.useMutation();
  const adTemplatesQuery = trpc.adTemplate.listThumbnails.useQuery({
    selectedBusinessEntityId: selectedBusinessEntityId || undefined
  });

  useEffect(() => {
    setAdTemplates(adTemplatesQuery.data?.thumbnails ?? []);
  }, [adTemplatesQuery.data?.thumbnails]);

  const isLoading = adTemplatesQuery.status === "loading";

  const handleDelete = useCallback(
    async (id: string): Promise<boolean> => {
      try {
        await deleteMutation.mutateAsync({ id });
        setAdTemplates(adTemplates.filter(template => template.id !== id));
        return true;
      } catch (err) {
        return false;
      }
    },
    [adTemplates, deleteMutation]
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        pl='48px'
        pr='48px'
        pt='19px'
        pb='28px'
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography data-testid='templatePageTitle' variant='h1'>
            Templates
          </Typography>
          {hasCreatePermissions && (
            <Button
              component={ReactRouterLink}
              to={`create${query}`}
              variant='contained'
              data-testid='createNewTemplate'
              sx={{ color: "mcBlue" }}
            >
              Create Template
            </Button>
          )}
        </Box>
        <Divider sx={{ paddingTop: "24px" }} />
      </Box>
      <Box
        display='flex'
        gap={3}
        justifyContent='Box-start'
        overflow={"auto"}
        maxHeight={"100%"}
        flexWrap={"wrap"}
        pb={3}
        pl='48px'
        pr='48px'
      >
        {isLoading ? (
          <>
            <Skeleton variant='rectangular' width={300} height={340} />
            <Skeleton variant='rectangular' width={300} height={340} />
            <Skeleton variant='rectangular' width={300} height={340} />
            <Skeleton variant='rectangular' width={300} height={340} />
          </>
        ) : (
          adTemplates.map(item => (
            <TemplateCard
              key={item.id}
              item={item}
              handleDelete={handleDelete}
            />
          ))
        )}
      </Box>
    </>
  );
}

export default Index;
