import { Stack, Typography, Box } from "@mui/material";
import { BrandKitGQL } from "@adflow/graphqlgen";
import { FontsUploadForm } from "./fontsUploadForm";
import { FontsTable } from "./fontsTable";
import { useQuery } from "@apollo/client";
import { trpc } from "../../../Templates/utils/apiClient";
import usePermissionToUpload from "../../hooks/permissionToUpload";
import { ScopedBusinessEntity } from "@ads/iam-library";

export function FontLibrary() {
  const { hasCreatePermissions, isUploadToDefault } = usePermissionToUpload();

  const scopedBusinessEntity = new ScopedBusinessEntity();
  const businessEntityId = scopedBusinessEntity.getId();

  const { loading, data, refetch } = useQuery(
    BrandKitGQL.GET_ALL_FONTS_BY_OWNER,
    {
      variables: {
        advertiserId: businessEntityId
      }
    }
  );

  const holdings = trpc.marketingCloud.listHoldings.useQuery(
    businessEntityId
      ? { selectedBusinessEntityId: businessEntityId }
      : undefined
  );

  const rows = data?.getFontFamiliesByOwner ?? [];
  return (
    <Box flex='1'>
      <Typography variant='h4' fontWeight={"bold"} marginBlockEnd={3}>
        Font Library
      </Typography>
      <Stack gap={2} marginBlockEnd={3}>
        <FontsTable rows={rows} loading={loading} />
      </Stack>
      <FontsUploadForm
        onSave={refetch}
        allowed={hasCreatePermissions}
        isUploadToDefault={isUploadToDefault}
        holdings={holdings.data?.holdings ?? []}
      />
    </Box>
  );
}
