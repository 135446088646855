import { useState, useEffect, useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { BrandKitGQL, ConsoleAPIGraph } from "@adflow/graphqlgen";
import { AdTemplate, FontFace } from "@adflow/types";
import { canEdit } from "../permissions";
import { TRPCClient } from "../apiClient";
import { parseFonts } from "@adflow/utils";
import UserContext from "../../../UserProvider/contexts/UserContext";

/**
 * Custom hook for fetching and storing AdTemplate, Brand, Fonts, and user permissions.
 */
export function useAdTemplateData(
  id: string,
) {
  const apolloClient = useApolloClient();
  const userContext = useContext(UserContext);
  const [adTemplate, setAdTemplate] = useState<AdTemplate | null>(null);
  const [brand, setBrand] = useState<ConsoleAPIGraph.Brand | null>(null);
  const [fonts, setFonts] = useState<FontFace[]>([]);
  const [ready, setReady] = useState(false);


  const [warningOpen, setWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");


  const [hasEditPermissions, setHasEditPermissions] = useState(false);

  /**
   * 1) Check user edit permissions
   */
  useEffect(() => {
    const checkPermissions = async () => {
      if (!userContext?.user) {
        setHasEditPermissions(false);
        return;
      }
      const canUserEdit = await canEdit(userContext.user);
      setHasEditPermissions(canUserEdit);
    };
    checkPermissions();
  }, [userContext?.user]);

  /**
   * 2) Fetch Ad Template -> If brand exists, fetch brand and fonts else fetch fonts from BrandKit
   */
  useEffect(() => {
    async function fetchAdTemplateData() {
      try {
        // Only fetch if adTemplate not yet loaded
        if (!adTemplate) {
          const data = await TRPCClient.client.adTemplate.findOne.query({ id });
          setAdTemplate(data);
        }
      } catch (error) {
        console.error("Error fetching ad template:", error);
      }
    }

    async function fetchBrand(brandId: string) {
      try {
        const data = await TRPCClient.client.consoleAPIGraph.getBrand.query(brandId);
        if (!data) {
          throw new Error("No data returned for brandId=" + brandId);
        }
        setBrand(data);

        // If brand has a team, fetch fonts from consoleAPIGraph
        if (data.team) {
          const fontData = await TRPCClient.client.consoleAPIGraph.listFonts.query({
            team: data.team,
          });
          if (fontData) {
            setFonts(fontData.fontFaces);
          }
        }
      } catch (error) {
        console.error("Error fetching brand:", error);
      }
    }

    async function fetchBrandKitFonts(advertiserId: string) {
      try {
        const { error, data } = await apolloClient.query({
          query: BrandKitGQL.GET_ALL_FONTS_BY_OWNER,
          variables: { advertiserId },
        });
        if (error) {
          console.error("Error fetching fonts from BrandKit:", error);
          setWarningMessage(
            "We encountered an issue while fetching fonts from the Brand Kit. " +
              "You can continue editing, but won't have access to Brand Kit fonts. " +
              "Please try again or contact support if the issue persists."
          );
          setWarningOpen(true);
          return;
        }
        const newFonts = data?.getFontFamiliesByOwner
          ? parseFonts(data.getFontFamiliesByOwner)
          : [];
        setFonts(newFonts);
      } catch (error) {
        console.error("Error fetching BrandKit fonts:", error);
      }
    }

    fetchAdTemplateData();

    // If we have an adTemplate, decide from where to fetch the fonts
    if (adTemplate) {
      if (adTemplate.brandId) {
        fetchBrand(adTemplate.brandId);
      } else if (adTemplate.businessEntityId) {
        fetchBrandKitFonts(JSON.stringify(adTemplate.businessEntityId));
      }
    }
  }, [apolloClient, id, adTemplate]);

  /**
   * 3) Mark ready once we have adTemplate and fonts
   */
  useEffect(() => {
    if (adTemplate) {
      setReady(true);
    }
  }, [adTemplate, fonts]);

  return {
    adTemplate,
    brand,
    fonts,
    ready,
    warningOpen,
    warningMessage,
    setWarningOpen,
    setWarningMessage,
    hasEditPermissions,
  };
}