import { FC, useMemo, useRef, useState } from "react";
import {
  FormControl,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";
import { AdElementText } from "@adflow/types";
import { Flex, Box } from "@chakra-ui/react";
import { buildAvailableFonts } from "./utils/fontUtils";

type Props = {
  selectedElement: AdElementText;
};

// Optional helper to check standard vs. custom vs. not installed
const getFontLabel = (
  family: string,
  standardFonts: string[],
  customFonts: { fontFamily: string }[]
) => {
  if (standardFonts.includes(family)) return " (Standard)";
  if (customFonts.some(f => f.fontFamily === family)) return " (Custom)";
  return " (Not installed)";
};

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().includes(searchText.toLowerCase());

const FontSelectionControls: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      updateElementData: state.updateElementData,
      customFonts: state.customFonts,
      standardFonts: state.standardFonts
    }),
    shallow
  );

  // Original font from the element
  const originalFamily = useRef(selectedElement.data.style.fontFamily);
  const originalStyle = selectedElement.data.style.fontStyle || "normal";

  const availableFontsGrouped = useMemo(() => {
    return buildAvailableFonts({
      customFonts: store.customFonts,
      standardFonts: store.standardFonts,
      originalFamily: originalFamily.current,
      originalStyle
    });
  }, [store.customFonts, store.standardFonts, originalFamily, originalStyle]);

  // Filtering by search
  const [searchText, setSearchText] = useState("");
  const displayedFonts = useMemo(() => {
    if (!searchText) return availableFontsGrouped;
    return availableFontsGrouped.filter(({ family }) =>
      containsText(family, searchText)
    );
  }, [searchText, availableFontsGrouped]);

  // The <Select> value will be "family||style"
  // If we have an original family + style, we use that for the initial.
  const initialValue =
    originalFamily.current && originalStyle
      ? `${originalFamily.current}||${originalStyle}`
      : "";

  const [selectedFont, setSelectedFont] = useState<string>(initialValue);

  const handleFontChange = (value: string) => {
    setSelectedFont(value);
    const [family, style] = value.split("||");

    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      style: {
        ...selectedElement.data.style,
        fontFamily: family,
        fontStyle: style
      }
    });
  };

  return (
    <Box mt={5}>
      <Typography variant='h4' fontWeight='bold'>
        Font
      </Typography>
      <Flex alignItems='center' mt={2}>
        <FormControl fullWidth>
          <Select
            labelId='font-select-label'
            value={selectedFont}
            onClose={() => setSearchText("")}
            renderValue={selected => {
              const [family, style] = (selected as string).split("||");
              // Show family + style + extra label
              const label = getFontLabel(
                family,
                store.standardFonts,
                store.customFonts
              );
              return (
                <Typography>
                  {family}
                  {label} - {style}
                </Typography>
              );
            }}
            MenuProps={{
              autoFocus: false,
              marginThreshold: null,
              PaperProps: {
                style: {
                  maxHeight: "300px",
                  overflowY: "auto"
                }
              }
            }}
          >
            <ListSubheader>
              <TextField
                size='small'
                autoFocus
                placeholder='Search fonts...'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== "Escape") e.stopPropagation();
                }}
              />
            </ListSubheader>

            {displayedFonts.map(({ family, styles }) => {
              const familyLabel = getFontLabel(
                family,
                store.standardFonts,
                store.customFonts
              );

              return [
                <ListSubheader
                  key={`subheader-${family}`}
                  sx={{
                    color: "mcBlue.darker",
                    fontWeight: "bold",
                    paddingLeft: 2
                  }}
                  disableSticky
                  disableGutters
                >
                  {family}
                  {familyLabel}
                </ListSubheader>,

                ...styles.map(style => (
                  <MenuItem
                    key={`${family}||${style}`}
                    value={`${family}||${style}`}
                    sx={{ color: "mcBlue.main", pl: 4 }}
                    onClick={() => handleFontChange(`${family}||${style}`)}
                  >
                    {style}
                  </MenuItem>
                ))
              ];
            })}
          </Select>
        </FormControl>
      </Flex>
    </Box>
  );
};

FontSelectionControls.displayName = "FontSelectionControls";

export default FontSelectionControls;
export type { Props as FontSelectionControlsProps };
