import { FC, useMemo } from "react";

import { Ad } from "@adflow/ad";
import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import { AdElement, AdflowAPI } from "@adflow/types";
import { Box } from "@chakra-ui/react";

import { useLoadFontsForAd } from "../../useFonts";
import PreviewOverlay from "../PreviewOverlay";
import SelectOverlay from "../SelectOverlay";
import { useMappedElements } from "../previewutils";
type Props = {
  elements: Array<AdElement>;
  brand: ConsoleAPIGraph.Brand | null;
  fonts: Array<{
    fontFamily: string;
    weight: number;
    style: string;
    src: string;
  }>;
  displayOptions?: AdflowAPI.AdTemplateDisplayOptions | null;
  selectedElementId?: string | null;
  isIndexPreview: boolean;
};

// 33 hours from now

const Preview: FC<Props> = ({
  elements,
  brand,
  fonts,
  displayOptions,
  selectedElementId,
  isIndexPreview
}) => {
  const mappedElements = useMappedElements(elements, displayOptions, brand);

  const mappedElementsWithoutSvg = useMemo(
    () => mappedElements.filter(element => element.type != "SVG"),
    [mappedElements]
  );

  const svgElement = useMemo(
    () => elements.find(x => x.type === "SVG"),
    [elements]
  );
  const transformScale = useMemo(() => {
    if (svgElement?.size.width && svgElement?.size.height) {
      const scaleX =
        svgElement.size.width > 600 ? 600 / svgElement.size.width : 1;
      const scaleY =
        svgElement.size.height > 600 ? 600 / svgElement.size.height : 1;
      return Math.min(scaleX, scaleY);
    }
    return 1;
  }, [svgElement]);

  const boxWidth = useMemo(
    () =>
      svgElement?.size.width ? svgElement?.size.width * transformScale : 600,
    [transformScale, svgElement]
  );
  const boxHeight = useMemo(
    () =>
      svgElement?.size.height ? svgElement?.size.height * transformScale : 600,
    [transformScale, svgElement]
  );
  const transformOrigin =
    isIndexPreview &&
    svgElement?.size.width === 1200 &&
    svgElement?.size.height === 1200
      ? "top left"
      : isIndexPreview
      ? "top"
      : "top left";

  useLoadFontsForAd(mappedElements, fonts);

  return (
    <Box
      position='relative'
      sx={{
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        transform: `scale(${transformScale})`,
        transformOrigin: transformOrigin
      }}
    >
      <Ad adElements={mappedElements} isPreview={true} />
      {isIndexPreview ? (
        <></>
      ) : (
        <>
          <PreviewOverlay
            adElements={mappedElementsWithoutSvg}
            selectedId={selectedElementId}
          />
          <SelectOverlay adElements={mappedElementsWithoutSvg} />
        </>
      )}
    </Box>
  );
};

Preview.displayName = "Preview";

export default Preview;
export type { Props as PreviewProps };
