import { useContext, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import TemplatesIcon from "./Icons/templates.svg";
import BrandKitIcon from "./Icons/brandKit.svg";
import CatalogueManagementIcon from "./Icons/catalogueManagement.svg";
import AdTagIcon from "./Icons/adTag.svg";
import VideoTagIcon from "./Icons/videoTag.svg";
import "./index.css";
import { useFeatureFlags } from "@adflow/utils";
import UserContext from "../UserProvider/contexts/UserContext";

interface IRoute {
  title: string;
  icon: string;
  link: string;
  enabled: boolean;
}

export default function SideNavigation() {
  const { flags: featureFlags } = useFeatureFlags();
  const [isVideoTagsEnabled, setIsVideoTagsEnabled] = useState(false);
  const [isClosed, setIsClosed] = useState(
    localStorage.getItem("mc-navigation-state") === "closed"
  );

  useEffect(() => {
    const navigationChangeEvt = (evt: MessageEvent) => {
      if (evt?.data?.key === "mc-navigation-state") {
        if (evt.data.value === "closed") setIsClosed(true);
        else setIsClosed(false);
      }
    };
    window.addEventListener("message", navigationChangeEvt);
    return () => {
      window.removeEventListener("message", navigationChangeEvt);
    };
  }, []);

  const { user } = useContext(UserContext);
  const isAdTagsEnabled = !(
    import.meta.env.VITE_BUILD_MODE === "prod" && !user?.isAdmin()
  );

  useEffect(() => {
    const checkVideoTagsEnabled = async () => {
      const result = await user?.hasFeature("video-tags");
      setIsVideoTagsEnabled(result ?? false);
    };
    checkVideoTagsEnabled();
  }, [user]);

  const routes: IRoute[] = [
    {
      title: "Templates",
      icon: TemplatesIcon,
      link: "/templates",
      enabled: true
    },
    {
      title: "Brand kit",
      icon: BrandKitIcon,
      link: "/brand-kit",
      enabled: true
    },
    {
      title: "Catalogues",
      icon: CatalogueManagementIcon,
      link: "/catalogues",
      enabled: true
    },
    {
      title: "Ad Tags",
      icon: AdTagIcon,
      link: "/ad-tags",
      enabled: isAdTagsEnabled
    },
    {
      title: "Video Tags",
      icon: VideoTagIcon,
      link: "/video-tags",
      enabled: isVideoTagsEnabled
    }
  ];

  const renderRoute = (route: IRoute) => {
    return (
      <NavLink to={route.link} key={route.title}>
        <div className='sideBarItem'>
          <div className='sideBarIcon_container'>
            <img
              className='sideBarIcon'
              src={route.icon}
              alt='{route.title} icon'
            />
          </div>
          <div className='sideBarTitle'>{route.title}</div>
        </div>
      </NavLink>
    );
  };

  const className = useMemo(() => (isClosed ? "closed" : ""), [isClosed]);

  return (
    <div id='sidebar' className={className}>
      {routes.filter(route => route.enabled).map(renderRoute)}
    </div>
  );
}
